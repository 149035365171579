<template>
  <div class="userLabelForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="商户标签名称"
        :rules="[
          { required: true, message: '请输入商户标签名称', trigger: 'blur' },
        ]"
        prop="labelName"
      >
        <v-input
          :width="width"
          placeholder="请输入商户标签名称"
          v-model="form.labelName"
        ></v-input>
      </el-form-item>

      <el-form-item label="关联用户标签" prop="relatedLabelIds">
        <v-cascader
          v-loading="userLabelLoading"
          placeholder="请选择"
          :width="width"
          v-model="relatedLabelIds"
          :options="sectionOps"
          :props="props"
        />
      </el-form-item>
      <!-- <el-form-item label="父级标签名称" prop="labelName">
        <v-input
          placeholder="请输入父级标签"
          disabled
          :value="form.parentName || '--'"
        ></v-input>
      </el-form-item> -->

      <!-- <el-form-item label="父级标签级别" prop="labelLevel">
        <v-input
          placeholder="请输入父级标签"
          disabled
          :value="
            form.labelLevel - 1 === 0 ? '--' : `${form.labelLevel - 1}级标签`
          "
        ></v-input>
      </el-form-item> -->

      <el-form-item label="标签说明" prop="remarks">
        <v-input
          clearable
          type="textarea"
          v-model="form.remarks"
          :width="500"
          :maxlength="200"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  getLabelInfoByIdUrl,
  saveLabelInfoUrl,
  getLabelTreeListUrl,
} from "./api.js";

export default {
  data() {
    return {
      width: 220,
      submitConfig: {
        queryUrl: getLabelInfoByIdUrl,
        submitUrl: saveLabelInfoUrl,
      },
      form: {
        id: undefined,
        labelType: 2,
        labelName: "",
        remarks: "",
        // labelLevel: undefined,
        // parentId: undefined,
        // parentName: "", // 非后端需要参数
        relatedLabelIds: [],
      },
      /** v-cascader */
      userLabelLoading: false,
      sectionOps: [], // 用于显示的数据
      relatedLabelPathIds: [], // 添加treeIds的数据
      relatedLabelIds: [], // 数据显示
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
    };
  },
  watch: {
    // 选中的区域集合
    relatedLabelIds: {
      handler(val) {
        this.form.relatedLabelIds = val.map((a) => a[a.length - 1]);
        console.log(this.form.relatedLabelIds);
      },
      deep: true,
    },
  },
  async mounted() {
    const { id, labelLevel, parentName, parentId } = this.$route.query;
    await this.getLabelTreeList(id);
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id });
    } else {
      // this.form.parentId = parentId;
      // this.form.parentName = parentName;
    }
    // this.form.labelLevel = labelLevel;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        if (key === "relatedLabelIds") {
          let relatedLabelIds = [];
          const ids = data.relatedLabelIds;
          this.relatedLabelPathIds.forEach((item) => {
            if (ids.includes(item.id)) {
              relatedLabelIds.push([...item.treeIds]);
            }
          });
          this.relatedLabelIds = [...relatedLabelIds];
          return;
        }
        this.form[key] = data[key] || this.form[key];
      });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },

    async getLabelTreeList(bizLabelId) {
      this.userLabelLoading = true;
      let res = await this.$axios.get(getLabelTreeListUrl, {
        params: {
          labelType: 1,
          bizLabelId, // 商户标签id，商户标签编辑页面的场合必传
        },
      });
      if (res.code === 200) {
        let opt = this.dealDataAddParentIds(res.data);
        this.sectionOps = res.data;
      }
      this.userLabelLoading = false;
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.relatedLabelPathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
  },
};
</script>
<style lang="less" scoped>
.realNameAuditForm {
  box-sizing: border-box;
  height: 100%;
  .formItem {
    ::v-deep .el-form-item__content {
      display: block;
      .vTag {
        margin-right: 20px;
      }
    }
  }
  .address {
    .item {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      .item-l {
        width: 200px;
        font-size: 14px;
        line-height: 16px;
        overflow-x: auto;
      }
      .item-r {
        margin-left: 20px;
        .v-control {
          margin-right: 10px;
        }
      }
    }
  }
  .caritem {
    margin-bottom: 10px;
  }
}
</style>
<style lang="less">
.realNameAuditForm-addAddress .v-control {
  display: flex;
  align-items: center;
}
.realNameAuditForm-addAddress .v-control label {
  width: 80px !important;
}
</style>
