// const getInitInfoURL = `/gateway/blade-system/userManagement/getInitInfo`;
// const getUserListURL = `/gateway/blade-system/userManagement/getUserList`;
// const getUserDetailURL = `/gateway/blade-system/userManagement/getUserDetail`;
// const registerURL = `/gateway/hc-mini/user/mini/edit`;
// //导出用户列表
// const exportUserUrl = `/gateway/blade-system/userManagement/exportUserList`;
// //房号列表
// const roomListUrl = `/gateway/hc-mini/user/mini/user-asset-list`;
// // 添加房号
// const addRoomListUrl = `/gateway/hc-mini/user/mini/register`;
// // 退出房号
// const outRoomListUrl = `/gateway/hc-mini/user/mini/exit-user`;
// //获取积分
// const getPoints = `/gateway/hc-accumulated/pointProcess/doPointProcess`;
// //查询指定用户绑定车位列表
// const getUserCarportListURL = `/gateway/hc-space/space/getUserCarportList`;
// //查询指定用户绑定车辆列表 /
// const getUserCarListURL = `/gateway/hc-space/space/getUserCarList`;
// const getCarportListURL = `/gateway/hc-space/space/getCarportList`;
// //获取园区列表
// const getCommunityListUrl = `/gateway/hc-space/space/community`;
// //添加车位
// const addUserCarportUrl = `/gateway/hc-space/space/addUserCarport`;
// //删除车位
// const deleteUserCarportUrl = `/gateway/hc-space/space/deleteUserCarport`;
// //添加车辆
// const addUserCarInfoUrl = `/gateway/hc-space/space/addUserCarInfo`;
// //删除车辆
// const deleteUserCarInfoUrl = `/gateway/hc-space/space/deleteUserCarInfo`;

//新增/编辑标签
const saveLabelInfoUrl = `/gateway/blade-system/label/saveLabelInfo`;
//标签列表查询
const getLabelInfoListUrl = `/gateway/blade-system/label/getLabelInfoList`;
//标签详情查询
const getLabelInfoByIdUrl = `/gateway/blade-system/label/getLabelInfoById`;
//用户标签树查询
const getLabelTreeListUrl = `/gateway/blade-system/label/getLabelTreeList`;
//标签关联用户列表查询
const getRelatedUserListUrl = `/gateway/blade-system/label/getRelatedUserList`;

export {
  saveLabelInfoUrl,
  getLabelInfoListUrl,
  getLabelInfoByIdUrl,
  getRelatedUserListUrl,
  getLabelTreeListUrl,
  // getUserCarportListURL,
  // getInitInfoURL,
  // getUserListURL,
  // getUserDetailURL,
  // registerURL,
  // exportUserUrl,
  // roomListUrl,
  // addRoomListUrl,
  // outRoomListUrl,
  // getPoints,
  // getCarportListURL,
  // getUserCarListURL,
  // getCommunityListUrl,
  // addUserCarportUrl,
  // addUserCarInfoUrl,
  // deleteUserCarportUrl,
  // deleteUserCarInfoUrl,
};
